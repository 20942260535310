<template>
    <modal name="WarningAutopaymentModal" width="308px" height="auto" @closed="onExit">
        <div class="modal--view">
            <div class="modal--head">
                <a @click="$modal.hide('WarningAutopaymentModal')"><span class="icon-close"></span></a>
            </div>
            <div class="modal--body">
                <div class="title--container">
                    <p>Предупреждение</p>
                </div>
                <div class="text--container">
                    <p>Вы можете выключить автоплатеж в любое время.</p>
                </div>
                <div class="button--container">
                    <button @click="exit">ОК</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    export default {
        name: "WarningAutopaymentModal",
        methods:{
            onExit(){
                this.$emit('exit')
            },

            exit(){
                this.$modal.hide('WarningAutopaymentModal')
            }
        }
    }
</script>

<style scoped lang="scss">
    .modal--view{
        width: 308px;
        height: auto;
        background: #FFFFFF;
        border: 0 solid #F2F2F2;
        box-shadow: 0 2px 7px 1px rgba(88,88,88,0.40);
        border-radius: 4px;
        display: flex;
        flex-direction: column;

        .modal--head{
            display: flex;
            justify-content: flex-end;
            margin-top: 16px;
            .icon-close{
                margin-right: 16px;
                cursor: pointer;
                color: rgb(161,160,161);
            }


            a{
                &:hover{
                    filter: brightness(87.5%);
                }
            }
        }

        .modal--body{
            width: 100%;
            .title--container{
                margin-top: 8px;
                width: 100%;
                p{
                    font-family: Roboto, "sans-serif";
                    font-weight: 600;
                    font-size: 24px;
                    color: #745489;
                    width: 100%;
                    text-align: center;
                }
            }

            .text--container{
                margin-top: 13px;
                p{
                    font-family: Roboto, "sans-serif";
                    font-weight: 300;
                    font-size: 14px;
                    color: #141213;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    width: calc(100% - 40px);
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }

            .button--container{
                margin-top: 20px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
                button{
                    font-family: Roboto, "sans-serif";
                    font-weight: 400;
                    font-size: 12px;
                    color: #CA4EBB;
                    letter-spacing: 2px;
                    text-align: center;
                    cursor: pointer;
                    border: 0;
                    outline: 0;
                    padding-left: 21px;
                    padding-right: 21px;
                    height: 32px;
                    border-radius: 8px;
                    background-color: #F1F1F1;
                    &:hover{
                        filter: brightness(87.5%);
                    }
                }
            }
        }
    }
</style>

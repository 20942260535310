<template>
    <div style="width:100%;height:auto;padding-bottom: 150px;overflow: scroll;-webkit-overflow-scrolling: touch;">
        <loader v-show="isLoading"></loader>
        <div class="pay--container" :class="{'minified' : isLoading}">
            <div class="card-outer--container">
                <div class="expired-text--container" v-if="profile._payStatus === 1 && !isMobile">
                    <p v-if="paidInvoices.length > 1">Тариф оплачен до {{expiredDate}}</p>
                    <p v-else-if="paidInvoices.length === 1">Бесплатный период до {{expiredDate}}</p>
                </div>
                <div class="card--container" v-if="activeCard != null">
                    <div class="card--img-container" @click="showActiveCard" draggable="false">
                        <div class="card-type--container" draggable="false">
                            <img class="visa__img" v-if="activeCard.cardType === 'Visa'" src="../../assets/icons/visa-new.svg">
                            <img class="visa__img" v-if="activeCard.cardType === 'MasterCard'" src="../../assets/icons/mastercard.svg">
                            <img class="visa__img" v-if="activeCard.cardType === 'Maestro'">
                            <img class="visa__img" v-if="activeCard.cardType === 'МИР'" src="../../assets/icons/mir.svg">
                        </div>
                        <img class="radial-gradint__img" src="../../assets/img/Softight.png" draggable="false">
                        <p class="last-four__text">{{activeCard.cardLastFour}}</p>
                        <p class="expired-date__text">{{activeCard.cardExpDate}}</p>
                    </div>
                    <div class="pay-info--container" v-if="(activeCard != null && profile._payStatus === 1)">
                        <div class="switch--container" >
                            <p>Автопродление</p>
                            <graphic-switch :checked="isEnableRecurrent" v-on:change="onChangeRecurrent"></graphic-switch>
                        </div>
                        <div class="auto-pay--text-container">
                            <p class="auto-pay__text">Автоматически продлевает тариф каждый месяц. Не волнуйтесь, вы в любой момент сможете отменить подписку</p>
                        </div>
                    </div>
                </div>
                <div class="expired-text--container" v-if="profile._payStatus === 1 && isMobile">
                    <p v-if="paidInvoices.length ===1">Бесплатный период до {{expiredDate}}</p>
                    <p v-else-if="paidInvoices.length > 1">Тариф оплачен до {{expiredDate}}</p>
                </div>

            </div>
            <div class="second-block--container">
                <div class="tariffs-outer--container" v-if="profile._payStatus !== 1 && tariffs.length !== 1" :class="{'selected' : selectedTariff.id != null}">
                    <div class="title--container">
                        <p>Выберите тариф</p>
                    </div>
                    <div class="tariffs--container">
                        <tariff-card v-for="tariffPeriod in tariff.tariffPeriods"
                                     v-bind:key="tariffPeriod.id"
                                     class="tariff--card-container"
                                     :classes-count="tariff.classesCount"
                                     :months="tariffPeriod.periodMonth"
                                     :price-per-month="getPricePerMonth(tariff,tariffPeriod)"
                                     :id-tariff="tariffPeriod.id"
                                     @click.native="onSelectTariff(tariff.id,tariffPeriod.id)"
                                     :class="{'selected' : selectedTariff.id == tariffPeriod.id}"
                        ></tariff-card>
                    </div>
                    <div class="text-footer--container" v-if="selectedTariff.id == null">
                        <p>Период каникул не оплачивается</p>
                    </div>
                    <div class="active--invoice--container">
                        <div class="invoice--list-item active" v-show="activeInvoice.price != 0">
                            <div class="info-pay--container">
                                <div class="info--container">
                                    <p class="title__text">К оплате</p>
                                    <p class="date__text">{{activeInvoice.date}}</p>
                                </div>
                                <div class="pay-count--container">
                                    <p v-if="activeInvoice.price > 0">{{activeInvoice.price}}.00 ₽ </p>
                                </div>
                            </div>
                            <div class="class-pay--container">
                                <div class="class-info--container">
                                    <p class="class__text" v-for="classes of activeInvoice.classes">{{classes.fullName}} {{ (classes.type === 1) ? "группа" : "класс"}}</p>
                                </div>
                                <div class="buttons--container">
                                    <material-button color="pink-gradient normal" value="Оплатить" size="xxs" @click.native="onSelectPeriod(activeInvoice)"></material-button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="invoices--container">
                    <div class="head">
                        <div class="title--container">
                            <p>Счета</p>
                        </div>
                      <div class="active--invoice--container" v-if="tariffs.length === 1">
                        <div class="invoice--list-item active" v-show="activeInvoice.price != 0">
                          <div class="info-pay--container">
                            <div class="info--container">
                              <p class="title__text">К оплате</p>
                              <p class="date__text">{{activeInvoice.date}}</p>
                            </div>
                            <div class="pay-count--container">
                              <p v-if="activeInvoice.price > 0">{{activeInvoice.price}}.00 ₽ </p>
                            </div>
                          </div>
                          <div class="class-pay--container">
                            <div class="class-info--container">
                              <p class="class__text" v-for="classes of activeInvoice.classes">{{classes.fullName}} {{ (classes.type === 1) ? "группа" : "класс"}}</p>
                            </div>
                            <div class="buttons--container">
                              <material-button color="pink-gradient normal" value="Оплатить" size="xxs" @click.native="onSelectPeriod(activeInvoice)"></material-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="body">
                        <div class="separator"></div>
                        <div class="invoices--list">
                            <div class="invoice--list-item" v-for="(invoice,index) in paidInvoices" >
                                <div class="info-pay--container">
                                    <div class="info--container">
                                        <p class="title__text" v-if="index === paidInvoices.length-1 ">Бесплатно</p>
                                        <p class="title__text" v-else>Оплачено</p>

                                        <p class="date__text">{{invoice.date}}</p>
                                    </div>
                                    <div class="pay-count--container">
                                        <p>{{invoice.price}}.00 ₽ </p>
                                    </div>
                                </div>
                                <div class="class-pay--container">
                                    <div class="class-info--container">
                                        <p class="class__text" v-for="classes of invoice.classes">{{classes.fullName}} класс</p>
                                    </div>
                                    <div class="buttons--container" v-if="index !== paidInvoices.length-1">
                                        <a @click="downloadInvoice(invoice)"><span class="icon-invoice"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <edit-card-modal-new></edit-card-modal-new>
            <delete-card-modal-new v-on:delete="deleteCard"></delete-card-modal-new>
            <payment-success-modal-new v-on:exit="updatePage"></payment-success-modal-new>
            <payment-failed-modal-new v-on:exit="updatePage"></payment-failed-modal-new>
            <connect-auto-payment-modal v-on:enable="enableRecurrent" v-on:decline="declineChangesRecurrent"></connect-auto-payment-modal>
            <disconnect-auto-payment-modal v-on:decline="declineChangesRecurrent" v-on:enable="disableRecurrent"></disconnect-auto-payment-modal>
            <warning-autopayment-modal v-on:exit="onRefresh"></warning-autopayment-modal>
        </div>
    </div>
</template>

<script>
    import GraphicSwitch from "@/components/switch/GraphicSwitch";
    import MaterialButton from "@/components/button/MaterialButton";
    import EditCardModalNew from "@/components/modal/EditCardModalNew";
    import DeleteCardModalNew from "@/components/modal/DeleteCardModalNew";
    import PaymentSuccessModalNew from "@/components/modal/PaymentSuccessModalNew";
    import PaymentFailedModalNew from "@/components/modal/PaymentFailedModalNew";
    import ConnectAutoPaymentModal from "@/components/modal/ConnectAutoPaymentModal";
    import DisconnectAutoPaymentModal from "@/components/modal/DisconnectAutoPaymentModal";
    import Loader from "@/components/loader/loader";
    import WarningAutopaymentModal from "@/components/modal/WarningAutopaymentModal";
    import SelectTariffModalNew from "@/components/modal/SelectTariffModalNew";
    import moment from "moment";
    import TariffCard from "@/components/card/TariffCard";
    export default {
        name: "PayViewNew",
        components: {
            TariffCard,
            SelectTariffModalNew,
            WarningAutopaymentModal,
            Loader,
            DisconnectAutoPaymentModal,
            ConnectAutoPaymentModal,
            PaymentFailedModalNew,
            PaymentSuccessModalNew, DeleteCardModalNew, EditCardModalNew, MaterialButton, GraphicSwitch},
        data(){
            return {
                activeCard : null,
                activeInvoice : null,
                invoices : [],
                isEnableRecurrent: null,
                isLoading: true,
                selectedTariff: {}
            }
        },
        computed:{

            isMobile(){
                return screen.width < 992
            },
            paidInvoices(){
                return this.invoices.filter((invoice) => {
                    return invoice.status
                }).reverse()
            },
            tariffs(){
                return this.$store.getters.TARIFFS
            },

            tariff(){
                const tariffs = this.tariffs;
                return tariffs[0]
            },

            expiredDate(){
                const profile = this.$store.getters.PROFILE
                return moment(profile._expiredAt.date).format("DD-MM-YYYY")
            },

            profile(){
                const profile = this.$store.getters.PROFILE;

                if (profile == null) return {}
                return profile
            }
        },
        methods:{
            getPricePerMonth(tariff,tariffPeriod){
                if (tariffPeriod.id === 4) return 275
                else if (tariffPeriod.id === 6) return 245;
                else return tariff.price - (tariffPeriod.discount/tariffPeriod.periodMonth)
            },

            showActiveCard(){
                this.$modal.show("EditCardModalNew",{value : this.activeCard})
            },


            async deleteCard(){
                const res = await this.$store.dispatch("DELETE_ACTIVE_CARD",{activeCard : this.activeCard})
                this.$router.go(0)
            },

            async onPaymentResult(res){
                console.log("res",res)
                if (res.success){
                    this.$modal.show("PaymentSuccessModalNew");
                } else {
                    this.$modal.show("PaymentFailedModalNew",{cardHolderMessage : res.cardHolderMessage})
                }
            },

            async pay(){
                await this.$store.dispatch("GET_TARIFFS")
                this.$modal.show("SelectTariffModalNew",{tariff : this.tariffs[0]})
            },

            payWithCloudPayments(tariffId,periodId,sum,period,text){
                const profile = this.$store.getters.PROFILE

                var data = {};
                data.payApiInfo = {tariffId : tariffId,periodId : periodId}
                data.cloudPayments = {recurrent: { interval: 'Month', period: period}}; //создание подписки прямо сейчас

                //@ts-ignore
                var widget = new cp.CloudPayments();
                widget.charge({ // options
                        publicId: 'pk_cec726583b225647eec0bd5eba8ce',  //id из личного кабинета
                        //description: 'Доступ к сервису видеонаблюдения SOVA по подписке', //назначение
                        description: text,
                        amount: sum, //сумма
                        currency: 'RUB', //валюта
                        skin: "modern", //дизайн виджета
                        accountId: profile._id,
                        data: data
                    },
                    (options) =>  { // success
                        this.$modal.show("WarningAutopaymentModal")
                    },
                    (reason, options) => { // fail
                        this.$router.go(0)
                    });
            },

            async payWithApi(tariffId,periodId){
                const res = await this.$store.dispatch("PAY",{isEnableRecurrent : this.isEnableRecurrent,tariffId: tariffId,periodId : periodId })
                await this.onPaymentResult(res)
            },


            onSelectTariff(tariffId,periodId){

                const tariff = this.tariffs.filter(tariff => tariff.id === tariffId)[0]
                const period = tariff.tariffPeriods.filter(period => period.id === periodId)[0]



                if (this.selectedTariff.id === periodId){
                    this.selectedTariff = {}
                    this.activeInvoice.price = 0

                } else {
                    this.selectedTariff = {...period}
                    const priceSum = (tariff.price * period.periodMonth) - period.discount
                    let text = `Доступ к сервису видеонаблюдения SOVA по подписке ${period.description}`

                    this.activeInvoice.price = 0
                    TweenLite.from(".active--invoice--container",1,{opacity : 0}).delay(0.2).play()
                    this.activeInvoice.tariffId = tariffId
                    this.activeInvoice.periodId = periodId
                    this.activeInvoice.text = text
                    this.activeInvoice.price = priceSum
                    TweenLite.to(".active--invoice--container",.5,{opacity : 1}).delay(0.2).play()

                }
            },

            async onChangeRecurrent(checked){
                console.log(checked)
                if (this.isEnableRecurrent != null && this.isEnableRecurrent !== checked){
                    await this.$store.dispatch("GET_PROFILE")
                    const profile = this.$store.getters.PROFILE
                    if (this.activeCard != null){
                        if (!this.isEnableRecurrent){
                            this.$modal.show("ConnectAutoPaymentModal",{user:profile})
                        } else {
                            this.$modal.show("DisconnectAutoPaymentModal",{user:profile})
                        }
                    }
                    this.isEnableRecurrent = checked

                }
            },

            declineChangesRecurrent(){
                const profile = this.$store.getters.PROFILE
                this.activeCard = {...profile._activeCard}
                this.isEnableRecurrent = this.activeCard.isEnableRecurrent
            },

            updatePage(){
                this.isLoading = true
                setTimeout(async () => {
                    await this.$store.dispatch("UPDATE_LOCAL_PROFILE")
                    await this.init()
                },5000)
            },

            async enableRecurrent(){
                await this.$store.dispatch("CHANGE_RECURRENT",{isEnableRecurrent : this.isEnableRecurrent})
            },

            async disableRecurrent(){
                await this.$store.dispatch("CHANGE_RECURRENT",{isEnableRecurrent : this.isEnableRecurrent})
            },

            async downloadInvoice(invoice){
                const response = await this.$store.dispatch("DOWNLOAD_INVOICE",{id : invoice.id})
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `file.csv`)
                document.body.appendChild(link)
                link.click()
            },

            async init(){
                await this.$store.dispatch("GET_PROFILE")
                await this.$store.dispatch("GET_TARIFFS")
                const profile = this.$store.getters.PROFILE
                this.isLoading = true
                this.activeCard = (profile._activeCard != null ) ? {...profile._activeCard} : null
                this.invoices = [...profile.invoices]
                this.activeInvoice = this.invoices.filter((invoice) => !invoice.status)[0]

                if (this.activeCard != null){
                    this.isEnableRecurrent = this.activeCard.isEnableRecurrent
                }

                console.log(this.tariffs)
                this.isLoading = false

              this.$nextTick(() => {
                if (this.tariffs.length === 1){
                  const tariff = this.tariffs[0];
                  this.onSelectTariff(tariff.id,tariff.tariffPeriods[0].id)
                }
              })
            },

            onRefresh(){
                this.$router.go(0)
            },


            onSelectPeriod(activeInvoice){
                const tariff = this.tariffs.filter(tariff => tariff.id === activeInvoice.tariffId)[0]
                const period = tariff.tariffPeriods.filter(period => period.id === activeInvoice.periodId)[0]

                const priceSum = (tariff.price * period.periodMonth) - period.discount
                let text = `Доступ к сервису видеонаблюдения SOVA по подписке ${period.description}`


                if (this.activeCard != null){
                    this.payWithApi(tariff.id,period.id)
                } else {
                    this.payWithCloudPayments(tariff.id,period.id,priceSum,period.periodMonth,text)
                }
            }
        },


        async mounted(){
            await this.init()
        },


    }
</script>

<style scoped lang="scss">
    .pay--container{
        height: auto;


        &.minified{
            max-height: 0px;
            overflow: hidden;
        }

        @include for-desktop-up{
            margin-left: 40px;
            margin-right: 0;
            display: flex;
            flex-direction: row-reverse;
            width: 767px;
            justify-content: flex-end;

        }

        @include for-big-desktop-up{
            margin-left: 60px;
            margin-right: 0;
            display: flex;
            flex-direction: row-reverse;
            width: 767px;
            justify-content: flex-end;

        }

        @include for-desktop-devices{
            margin-top: 40px;
        }


        .card--container{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
            margin-bottom: 30px;

            @include for-phone-only{
                width: 335px;

            }

            @include for-phone-landscape-up{
                width: 480px;
            }

            @include for-tablet-up{
                width: 620px;
            }

            @include for-desktop-devices{
                margin-top: 20px;
                width: 378px;
                height: 340px;
                align-items: flex-start;
                margin-left: 54px;
            }



            .card--img-container{
                /*width: 237px;
                height: 153px;
                position: relative;
                background: url("../../assets/icons/visa.svg") no-repeat -22px -7px;
                background-size: 272px 188px;
                box-shadow: -5px 7px 22px 0 rgba(0, 0, 0, 0.4), -4px 10px 17px 0 rgba(0, 0, 0, 0.5);
                overflow: hidden;
                border-radius: 9px;*/

                width: 237px;
                height: 153px;
                position: relative;
                background: url("../../assets/icons/visa.svg") no-repeat -32px -16px, linear-gradient(-187deg, #404547 0%, #303A40 100%);

                background-size: 290px 200px;
                box-shadow: -5px 7px 22px 0 rgba(0, 0, 0, 0.4), -4px 10px 17px 0 rgba(0, 0, 0, 0.5);
                overflow: hidden;
                border-radius: 9px;
                cursor: pointer;
                transition: all 0.3s;
                .card-type--container{
                    position: absolute;
                    left: 165px;
                    top: 15px;
                    .visa__img{
                        width: 50px;
                        user-select: none; /* supported by Chrome and Opera */
                        -webkit-user-select: none; /* Safari */
                        -khtml-user-select: none; /* Konqueror HTML */
                        -moz-user-select: none; /* Firefox */
                        -ms-user-select: none; /* Internet Explorer/Edge */                    }
                }

                &:hover{
                    transform: scale(1.05);
                }

                &:focus{
                    transform: scale(0.95);
                }


                .radial-gradint__img{
                    position: absolute;
                    right: 0;
                    top: 0;
                }




                .last-four__text{
                    pointer-events: none;
                    font-family: Roboto, "sans-serif";
                    font-size: 17.41px;
                    text-align: left;
                    line-height: 17.41px;
                    left: 175px;
                    top: 78px;
                    position: absolute;
                    display: table;
                    color: transparent;

                    background-image: linear-gradient(0deg, #C8C8C8 16%, #C8C8C8 29%, #FFFFFF 33%, #FFFFFF 43%, #C8C8C8 49%, #C8C8C8 60%, #FFFFFF 66%, #FFFFFF 80%);
                    -webkit-background-clip: text;
                    -moz-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
                    user-select: none; /* supported by Chrome and Opera */
                    -webkit-user-select: none; /* Safari */
                    -khtml-user-select: none; /* Konqueror HTML */
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */                    //text-shadow: 0 0 1px rgba(0,0,0,0.80), 0 -2px 2px rgba(255,255,255,0.30), 0 1px 2px rgba(0,0,0,0.80), 0 1px 1px #FFFFFF, 0 -1px 0 rgba(0,0,0,0.30)
                }

                .expired-date__text{
                    pointer-events: none;
                    font-family: Roboto, "sans-serif";
                    font-size: 12.43px;
                    color: #FFFFFF;
                    text-align: left;
                    position: absolute;
                    left: 99px;
                    top: 110px;
                    display: table;
                    user-select: none; /* supported by Chrome and Opera */
                    -webkit-user-select: none; /* Safari */
                    -khtml-user-select: none; /* Konqueror HTML */
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                    background-image: linear-gradient(0deg, #C8C8C8 16%, #C8C8C8 29%, #FFFFFF 33%, #FFFFFF 43%, #C8C8C8 49%, #C8C8C8 60%, #FFFFFF 66%, #FFFFFF 80%);
                    -webkit-background-clip: text;
                    -moz-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);                }
            }

            .pay-info--container{
                display: flex;
                flex-direction: column;

                @include for-desktop-devices{
                    width: 375px;
                    background: #FFFFFF;
                    border: 0 solid #F2F2F2;
                    box-shadow: 0 2px 7px 1px rgba(88,88,88,0.40);
                    height: 165px;
                    border-radius: 10px;
                    margin-top: 22px;
                }

                .expired-text--container{
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 17px;
                    letter-spacing: 0.37px;
                    line-height: 14px;
                    font-family: Roboto, "sans-serif";
                    margin-top: 35px;
                }

                .switch--container{
                    display: flex;
                    margin-left: 49px;
                    margin-right: 49px;
                    margin-top: 35px;
                    align-items: center;

                    @include for-desktop-devices{
                        margin-left: 36px;
                        margin-right: 35px;
                        justify-content: space-between;
                    }

                    p{
                        margin-right: 46px;
                        font-family: Roboto, "sans-serif";
                        pointer-events: none;


                        @include for-phone-only{
                            font-size: 17px;
                            letter-spacing: 0.37px;
                            line-height: 14px;

                        }

                        @include for-phone-landscape-up{
                            font-size: 17px;
                            letter-spacing: 0.37px;
                            line-height: 14px;
                        }

                        @include for-tablet-up{
                            font-size: 18px;
                            letter-spacing: 0.39px;
                            line-height: 16.8px;
                        }

                        @include for-desktop-devices{
                            font-size: 17px;
                            letter-spacing: 0.37px;
                            line-height: 14px;
                        }


                    }
                }
            }



            .auto-pay--text-container{
                display: flex;
                margin-top: 17px;
                margin-left: 36px;
                @include for-mobile-devices{
                    display: none;
                }

                p{
                    font-family: Roboto, "sans-serif";
                    font-size: 11px;
                    color: #000000;
                    letter-spacing: 0.24px;
                    line-height: 16px;
                    width: 238px;
                    pointer-events: none;
                }
            }
        }

        .invoices--container{
            margin-left: auto;
            margin-right: auto;
            margin-top: 25px;

            @include for-desktop-devices{
                margin-top: 0px;

            }


            @include for-phone-only{
                width: 335px;
            }

            @include for-phone-landscape-up{
                width: 480px;
            }

            @include for-tablet-up{
                width: 620px;
            }

            @include for-desktop-up{
                width: 335px;
            }

            @include for-big-desktop-up{
                width: 335px;
            }

            .head{
                .title--container{
                    p{
                        font-family: Roboto, "sans-serif";
                        font-size: 28px;
                        color: #222222;
                        pointer-events: none;
                    }
                }
            }

            .body{
                margin-top: 13px;

                .invoice--list-item{
                    width: 100%;
                    height: auto;
                    background: #FFFFFF;
                    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12);
                    border-radius: 8px;
                    display: flex;
                    flex-direction: column;
                    transition: all 0.3s;


                    &:hover{
                        transform: scale(0.98);
                    }
                    .info-pay--container{
                        display: flex;
                        width: calc(100% - 18px);
                        margin-left: 9px;
                        margin-right: 9px;
                        justify-content: space-between;
                        margin-top: 8px;

                        .info--container{
                            .title__text{
                                font-family: Roboto, "sans-serif";
                                font-size: 17px;
                                letter-spacing: 0.14px;
                                pointer-events: none;

                            }

                            .date__text{
                                margin-top: 3px;
                                font-family: Roboto, "sans-serif";
                                font-size: 14px;
                                letter-spacing: 0.14px;
                                pointer-events: none;
                            }
                        }

                        .pay-count--container{
                            margin-top: 4px;
                            font-size: 17px;
                            font-family: Roboto, "sans-serif";
                            letter-spacing: 0.14px;
                        }

                    }

                    .class-pay--container{
                        display: flex;
                        width: calc(100% - 18px);
                        margin-left: 9px;
                        margin-right: 9px;
                        justify-content: space-between;
                        height: auto;
                        align-items: flex-end;
                        margin-top: 7px;
                        margin-bottom: 18px;



                        .class-info--container{
                            margin-bottom: 10px;

                            p{
                                font-family: Roboto, "sans-serif";
                                font-size: 13px;
                                letter-spacing: 0;
                                color: rgba(139,138,141,.5);
                                pointer-events: none;
                                line-height: 15px;
                            }
                        }

                        .buttons--container{
                            display: flex;
                            align-items: center;

                            a {
                                margin-right: 23px;
                                span{
                                    font-size: 29px;
                                }
                            }

                            button{
                                margin-left: 21px;
                            }
                        }
                    }
                }

                .separator{
                    width: 100%;
                    height: 1px;
                    border-bottom: 1px solid rgba(139,138,141,0.20);
                    margin-top: 15px;
                }

                .invoices--list{
                    height: auto;
                    width: 100%;
                    margin-bottom: 80px;

                    .invoice--list-item{
                        margin-top: 15px;
                        &:hover{
                            transform: scale(0.98);
                        }
                    }
                }
            }
        }

        .icon-invoice{
            font-size: 36px;
            cursor: pointer;
        }
    }


    *{
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }



    .tariffs-outer--container{

        display: flex;
        flex-direction: column;
        width: 100%;
        height: 280px;
        min-height: 280px;
        max-height: 430px;
        transition: height 0.3s;

        @include for-mobile-devices{
            height: 350px;
        }

        &.selected{
            height: 370px;


            @include for-mobile-devices{
                height: 430px;
            }
        }




        @include for-mobile-devices{
            background: rgba(191,191,191,0.09);
            box-shadow: inset 0 0 2px 0 rgba(87,87,87,0.20);
        }
        .title--container{
            margin-top: 35px;
            margin-left: auto;
            margin-right: auto;

            @include for-desktop-devices{
                margin-top: -20px;
            }

            @include for-phone-only{
                width: 335px;
            }

            @include for-phone-landscape-up{
                width: 480px;
            }

            @include for-tablet-up{
                width: 620px;
            }

            @include for-desktop-up{
                width: 335px;
            }

            @include for-big-desktop-up{
                width: 335px;
            }



            p{
                font-family: Roboto, "sans-serif";
                font-size: 17px;
                color: black;
                letter-spacing: 0.37px;
                line-height: 14px;

            }
        }

        .tariffs--container{
            display: flex;
            margin-top: 21px;
            justify-content: center;
            min-height: 186px;
            max-height: 186px;


            @include for-mobile-devices{
                margin-top: 24px;
            }




            .tariff--card-container{
                margin-right: 17px;
                transition: all .2s ease-in-out;
                cursor: pointer;
                overflow: hidden;
                transform: scale(1);
                box-shadow: 0 0 6px 0 rgba(0,0,0,0.29) ;
                border-radius: 4px;


                @include for-mobile-devices{
                    margin-right: 7px;
                }

                &:hover {
                    transform: scale(1.01);

                }

                &.selected {
                    transform: scale(0.96);
                    box-shadow: 0 0 6px 0 rgba(0,0,0,0.4) ;



                    &.hover{
                        transform: scale(0.96);
                    }
                }

            }

            .tariff--card-container:last-child{
                margin-right: 0;
            }
        }

        .text-footer--container{
            margin-top: 27px;
            margin-left: auto;
            margin-right: auto;

            margin-bottom: 28px;

            p{
                font-family: Roboto, "sans-serif";
                font-size: 12px;
                color: black;
                font-weight: 300;
                letter-spacing: 0.08px;
                line-height: 14px;
            }
        }
    }


    .active--invoice--container{
        opacity: 0;
        margin-left: auto;
        margin-right: auto;

        @include for-phone-only{
            width: 335px;
        }

        @include for-phone-landscape-up{
            width: 480px;
        }

        @include for-tablet-up{
            width: 620px;
        }

        @include for-desktop-up{
            width: 335px;
        }

        @include for-big-desktop-up{
            width: 335px;
        }

        .invoice--list-item{
            width: 100%;
            height: auto;
            background: #FFFFFF;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            transition: all 0.3s;

            margin-top: 31px;
            margin-bottom: 41px;


            &:hover{
                transform: scale(0.98);
            }
            .info-pay--container{
                display: flex;
                width: calc(100% - 18px);
                margin-left: 9px;
                margin-right: 9px;
                justify-content: space-between;
                margin-top: 8px;

                .info--container{
                    .title__text{
                        font-family: Roboto, "sans-serif";
                        font-size: 17px;
                        letter-spacing: 0.14px;
                        pointer-events: none;

                    }

                    .date__text{
                        margin-top: 3px;
                        font-family: Roboto, "sans-serif";
                        font-size: 14px;
                        letter-spacing: 0.14px;
                        pointer-events: none;
                    }
                }

                .pay-count--container{
                    margin-top: 4px;
                    font-size: 17px;
                    font-family: Roboto, "sans-serif";
                    letter-spacing: 0.14px;
                }

            }

            .class-pay--container{
                display: flex;
                width: calc(100% - 18px);
                margin-left: 9px;
                margin-right: 9px;
                justify-content: space-between;
                height: auto;
                align-items: flex-end;
                margin-top: 7px;
                margin-bottom: 18px;



                .class-info--container{
                    margin-bottom: 10px;

                    p{
                        font-family: Roboto, "sans-serif";
                        font-size: 13px;
                        letter-spacing: 0;
                        color: rgba(139,138,141,.5);
                        pointer-events: none;
                        line-height: 15px;
                    }
                }

                .buttons--container{
                    display: flex;
                    align-items: center;

                    a {
                        margin-right: 23px;
                        span{
                            font-size: 29px;
                        }
                    }

                    button{
                        margin-left: 21px;
                    }
                }
            }
        }

    }

    .card-outer--container{


        .expired-text--container{
            font-size: 17px;
            letter-spacing: 0.37px;
            line-height: 14px;
            font-family: Roboto, "sans-serif";
            margin-left: 58px;
            margin-top: 10px    ;

            @include for-mobile-devices{
                margin-top: 30px;
                width: 100%;
                display: flex;
                justify-content: center;
                margin-left: 0;

            }
        }
    }
</style>




























import {Component, Vue} from "vue-property-decorator";
import TariffCard from "@/components/card/TariffCard.vue";

@Component({
    components: {TariffCard}
})
export default class SelectTariffModalNew extends Vue{
    private tariff = {}



    get isMobile(){
        return screen.width < 960
    }


    get width(){
        return (!this.isMobile) ? "436px" : "361px"
    }

    get height(){
        return (!this.isMobile) ?  "289px" : "256px"
    }


    close(){
        this.$modal.hide("SelectTariffModalNew");
    }

    onBeforeOpen(e: any){
        this.tariff = {...e.params.tariff}
    }


    onSelectTariff(id: number,period : number){
        setTimeout(() => {
            this.$emit("select",id,period);
            this.close();
        },500)
    }

    getPricePerMonth(tariff : any,tariffPeriod : any){
        if (tariffPeriod.id === 4) return 275
        else if (tariffPeriod.id === 6) return 245;
        else return tariff.price - (tariffPeriod.discount/tariffPeriod.periodMonth)
    }
}
